var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"aria-labelledby":"profile-overview-title"}},[_c('div',{staticClass:"rounded-lg bg-white overflow-hidden shadow mt-5"},[_c('div',{staticClass:"bg-white p-4"},[_c('div',{staticClass:"sm:flex sm:items-center sm:justify-between"},[_c('div',{staticClass:"sm:flex sm:space-x-5"},[_c('div',{staticClass:"hidden sm:flex"},[_c('IconUserPlain',{staticClass:"h-20 w-20"})],1),_c('div',{staticClass:"flex items-center justify-content-center mt-2 text-center sm:mt-0 sm:pt-1 sm:text-left"},[_c('p',{staticClass:"text-sm font-medium text-gray-600 text-left mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('post.page.open_by'))+" ")]),(
                                      !(_vm.post.created_by || _vm.post.author_email)
                                  )?_c('span',{staticClass:"ext-blue-500 font-bold",class:_vm.post.author_type === 2
                                          ? 'cursor-pointer'
                                          : '',on:{"click":function($event){$event.preventDefault();return _vm.openCitizenProfile()}}},[_vm._v(" "+_vm._s(_vm.$t('post.page.unknown_citizen'))+" ")]):(!_vm.isAnonymousCitizen())?_c('span',[_vm._v(" "+_vm._s(_vm.$t('post.page.the_operator'))+" "),_c('span',[_vm._v(_vm._s(_vm.post.created_by.name)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('post.page.for_the_citizen'))+" "),_c('span',{staticClass:"ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left",class:_vm.post.author_type === 2
                                              ? 'cursor-pointer'
                                              : '',on:{"click":function($event){$event.preventDefault();return _vm.openCitizenProfile()}}},[_vm._v(_vm._s(_vm.post.author_email))]),_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.post.author_email),expression:"post.author_email",arg:"copy"}],staticClass:"ml-2 text-xl text-blue-500 far fa-copy",attrs:{"type":"button"}})]):(
                                      !_vm.post.created_by && _vm.post.author_email
                                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('post.page.the_citizen'))+" "),_c('span',{staticClass:"ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left",class:_vm.post.author_type === 2
                                              ? 'cursor-pointer'
                                              : '',on:{"click":function($event){$event.preventDefault();return _vm.openCitizenProfile()}}},[_vm._v(_vm._s(_vm.post.author_email))]),_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.post.author_email),expression:"post.author_email",arg:"copy"}],staticClass:"ml-2 text-xl text-blue-500 far fa-copy",attrs:{"type":"button"}})]):(
                                      _vm.post.author_type === 2 &&
                                      (_vm.post.author_email || _vm.post.created_by)
                                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('post.page.the_citizen'))+" "),(_vm.post.created_by)?_c('span',{staticClass:"ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left",on:{"click":function($event){$event.preventDefault();return _vm.openCitizenProfile()}}},[_vm._v(" "+_vm._s(_vm.post.created_by.name)+" ")]):_c('span',{staticClass:"ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left",on:{"click":function($event){$event.preventDefault();return _vm.openCitizenProfile()}}},[_vm._v(" "+_vm._s(_vm.post.author_email)+" ")]),_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                                          _vm.post.created_by
                                              ? _vm.post.created_by.name
                                              : _vm.post.author_email
                                      ),expression:"\n                                          post.created_by\n                                              ? post.created_by.name\n                                              : post.author_email\n                                      ",arg:"copy"}],staticClass:"ml-2 text-xl text-blue-500 far fa-copy",attrs:{"type":"button"}})]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('post.page.the_operator'))+" "),_c('span',{staticClass:"ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left",class:_vm.post.author_type === 2
                                              ? 'cursor-pointer'
                                              : '',on:{"click":function($event){$event.preventDefault();return _vm.openCitizenProfile()}}},[_vm._v(_vm._s(_vm.post.created_by.name))]),_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.post.created_by.name),expression:"post.created_by.name",arg:"copy"}],staticClass:"ml-2 text-xl text-blue-500 far fa-copy",attrs:{"type":"button"}})])])])]),(_vm.post.author_type === 1)?_c('div',{staticClass:"mt-5 flex justify-center sm:mt-0"}):(_vm.post.author_type === 2 || !_vm.post.author_type && (_vm.post.citizen_id || _vm.post.device_id) )?_c('div',{staticClass:"mt-5 flex justify-center sm:mt-0"},[_c('button',{staticClass:"flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",on:{"click":function($event){$event.preventDefault();return _vm.openCitizenProfile()}}},[_vm._v(" "+_vm._s(_vm.$t('post.page.button.profile'))+" ")]),_c('CitizenProfile',{ref:"citizenProfile",attrs:{"post":_vm.post}})],1):_c('div',{staticClass:"mt-5 flex justify-center sm:mt-0"},[_c('b-tag',{staticClass:"is-info"},[_vm._v(" "+_vm._s(_vm.$t('post.page.citizen'))+" ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }